@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: url(assets/fonts/Inter-ExtraLight.ttf);
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url(assets/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/Inter-Bold.ttf);
}
